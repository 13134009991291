<template>
  <div class="send-order">
    <p class="send-title">确认订单寄送信息</p>
    <div class="order-main" v-if="info">
      <div class="order-info">
        <p class="info-title">订单</p>
        <div class="info-tips">
          <div class="tips">
            <p> {{ info.orderNumber }} <span v-for="i in info.tags" :key="i">{{ i }}</span></p>
           <span v-if="info.money"> {{ info.money.currency }} {{ info.money.value }}</span>
          </div>
          <div class="info-admin">
            <img :src="defaultImg" alt="">
            <span>{{ userInfo.fullName }}</span>
            创建于
            <span>{{ $moment(info.whenCreated).format('YYYY年MM月DD日 HH:mm:ss') }}</span>
          </div>
        </div>
      </div>
      <div class="order-info">
        <p class="info-title">客户</p>
        <div class="info-tips cusTips">
          <div class="info-admin">
            <img :src="defaultImg" alt="">
            <span>{{ studentProfile.fullName }}（{{ studentProfile.code }}）</span>
          </div>
        </div>
      </div>
    </div>
    <div class="order-form">
      <p class="info-title">客户</p>
      <a-form class="ant-advanced-search-form" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" :form="form">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="收货人">
              <a-input v-decorator="['name', {}]" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货人电话">
              <a-input
                v-decorator="[
                  'phoneNumber',
                  {
                    rules: [{ required: true, message: '手机!' }],
                  },
                ]"
                style="width: 100%"
              >
                <a-select
                  slot="addonBefore"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-decorator="['prefix', { initialValue: '+86' }]"
                  style="width: 70px"
                >
                  <a-select-option :value="i" v-for="i of areaCodeArr" :key="i">
                    {{ i}}
                  </a-select-option>
                </a-select>
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货国家/地区">
              <!-- <a-input v-decorator="['address.country', {}]" /> -->
               <a-select
                show-search
                placeholder="收货国家/地区"
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="['address.country', { rules: [{ required: true, message: '请选择收货国家/地区!' }] }]"
              >
                <a-select-option :value="i.name" v-for="i of countries" :key="i.label">
                  {{ i.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="州/省">
              <a-input v-decorator="['address.province', {}]" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="城市">
              <a-input v-decorator="['address.city', {}]" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="街道">
              <a-input v-decorator="['address.street', {}]" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="邮政编码">
              <a-input v-decorator="['postCode', {}]" />
            </a-form-item>
          </a-col>
        </a-row>
         <div class="edit-btn">
          <a-button @click="cancal"> 取消 </a-button>
          <a-button type="primary" @click="onSave"> 提交 </a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>
<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { countries, orderInfo, logistics } from '@/api/headTeacher';
import { ACCESS_TOKEN, REMEMBERME } from '@/store/mutation-types';
const session = storage.createStore(sessionStorage);
import defaultImg from '@/assets/service_pic_head.png';
export default {
  data() {
    return {
      defaultImg,
      countries: null,
      studentProfile: session.get('studentProfile'),
      userInfo: storage.get('userInfo'),
      orderId: session.get('orderId'),
      info: null,
      form: this.$form.createForm(this),
      areaCodeArr:[]
    };
  },
  mounted(){
    countries().then((i) => {
      this.countries = i.data.content;
       this.areaCodeArr=this.unique(i.data.content);
    });
    this.getOrderInfo();
  },
  methods: {
      onSave(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const datas = values;
          datas.phoneNumber = `${datas.prefix} ${datas.phoneNumber}`
          delete datas.prefix
          logistics({ uuid: this.info.uuid, data: datas }).then(() => {
            this.$router.push('/order')
          });
        }
      });
    },
    getOrderInfo() { 
      const parame = {
        orderId: this.orderId,
      };
      orderInfo(parame).then((res) => {
        this.info = res.data;
        this.form.getFieldDecorator('name', { initialValue: res.data.logistics.receiver.name });
        this.form.getFieldDecorator('phoneNumber', { initialValue: res.data.logistics.receiver.phoneNumber.split(' ')[1] });
        this.form.getFieldDecorator('prefix', { initialValue: res.data.logistics.receiver.phoneNumber.split(' ')[0] });
        this.form.getFieldDecorator('address.country', { initialValue: res.data.logistics.receiver.address.country });
        this.form.getFieldDecorator('address.province', { initialValue: res.data.logistics.receiver.address.province });
        this.form.getFieldDecorator('address.city', { initialValue: res.data.logistics.receiver.address.city });
        this.form.getFieldDecorator('address.street', { initialValue: res.data.logistics.receiver.address.street });
        this.form.getFieldDecorator('postCode', { initialValue: res.data.logistics.number });
      });
    },
      unique(arr){
        var arr1 = [];     
        for(var i=0,len=arr.length;i<len;i++){
           if(arr1.indexOf(arr[i].areaCode) === -1){
                  arr1.push(arr[i].areaCode);
                }
            }
               return arr1;     
           },
    cancal() {
      window.history.back();
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
  },
};
</script>
<style lang="less" scoped>
.send-order{
  background-color: #fff;
  padding: 20px;
  p{
    margin-bottom: 0;
  }
  .send-title{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .info-title{
    font-weight: 600;
    font-size: 16px;
    padding-left: 8px;
    margin-bottom: 20px;
    &::before {
    content: '';
    display: block;
    float: left;
    margin: 4px 6px 0 0;
    width: 3px;
    height:16px;
    background-color:#41CC83;
    border-radius: 0px 2px 2px 0px;
  }
  }
  .order-main{
    display: flex;
    justify-content: flex-start;
    .order-info{
      height: auto;
   
      .info-tips{
        border-radius: 8px;
        width: 400px;
        padding: 10px;
        color: #999;
        border: 1px solid #eee;
        height: 82px;
        margin-right: 20px;
        .tips{
          display: flex;
          justify-content: space-between;
          
          span{
            color: #333;
            font-weight: 600;
            
          }
        }
        .info-admin{
          img{
            width: 30px;
            height: 30px;
          }
          color: #999;
          span{
            color: #333;
          }
        }
        p{
          color:#41CC83;
          font-size: 16px;
          margin-bottom: 15px;
        }
      }
      .cusTips{
        display: flex;
        align-items: center;
        img{
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
      }
    }
  }
  .order-form{
    margin-top: 20px;
  }
  .edit-btn {
    .ant-btn {
      margin: 0 10px;
    }
   
  }
}
</style>
